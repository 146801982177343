import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';

export interface ITrackingService {
  trackingService?: TrackingServiceV2;
}

export enum ImpressionTrackingType {
  PARENT = 'parent',
  TOPLEVEL = 'top-level',
  SAMELEVEL = 'same-level',
}
export type ImpressionTrackingObject = {
  type: ImpressionTrackingType;
  label: string;
  value: string;
};

export type ActiveLinks = {
  id: string;
  index: number;
  text: string;
  href?: string;
  target?: string;
  parentLabel?: string; // set from url
  subLevelChildLabel?: string; // Label on secondary level CFM
  urlIndex?: number;
  overviewLinkSelected?: boolean;
};

export type TopLinksActive = {
  index: number;
  url: string;
};

export type SubNavActive = {
  index: number;
  interaction?: number;
  subLinks?: {
    hasLinks: boolean;
    active: boolean;
    text: string;
  };
};

export type InPageNavConfig = {
  // Falcon Content
  target?: string;
  text?: string;
  accordionLabel?: string;
  parentPageInpageNavigation?: InternalLink;
  href?: string;
  internalLink?: string;
  mainLink?: string;
  subLevelInpageNavigation?: SubLevelInpageNavigation[];
  sameLevelInpageNavigation?: SameLevelInpageNavigation[];
  priorityNavigation?: SameLevelInpageNavigation[];
  uiTheme?: string;
  // UI content
  sameLevelActiveLink?: ActiveLinks;
  topLevelActiveLink?: ActiveLinks;
  secondLevelActiveLink?: ActiveLinks;
  topActiveLinks?: TopLinksActive[];
  subNavActive?: SubNavActive;
  isLoadedFromUrl?: boolean;
  impressionTracking?: ImpressionTrackingObject;
  toggleVisible?: {
    visible: boolean;
    label: string;
    inputName: string;
  };
  priorityNavLoaded?: boolean;
  prioprityNavActive?: {
    isDesktop: boolean;
    interaction: number;
    index?: number;
    path?: string;
    active?: boolean;
  };
  removeStickiness?: boolean;
};

export type SameLevelInpageNavigation = {
  id: string;
  path: string;
  contentPath: string;
  status: string;
  created: string;
  modified: string;
  title: string;
  description: string;
  model: Model;
  fields: Fields;
};

export type ActiveSubLevelItem = {
  id: string;
  parentLabel: string;
  subLevelChildLabel: string;
  index: number;
  subLevelInpageNavigation: MainLink[];
};

export type MainLink = {
  id: string;
  path: string;
  contentPath: string;
  status: string;
  created: string;
  modified: string;
  title: string;
  description: string;
  model: Model;
  fields: {
    Text: string;
    href: Href;
    linkUrl: string;
    Target: string;
  };
};

export type SubLevelInpageNavigation = {
  id: string;
  path: string;
  contentPath: string;
  status: string;
  created: string;
  modified: string;
  title: string;
  description: string;
  model: Model;
  fields: Fields;
};

export type Fields = {
  mainLink?: MainLink;
  subLevelChildLabel?: string;
  subLinks?: MainLink[];
  target?: string;
  text?: string;
  href?: Href;
  linkUrl?: string;
};

export type InternalLink = {
  id: string;
  path: string;
  contentPath: string;
  status: string;
  created: string;
  modified: string;
  title: string;
  description: string;
  model: Model;
  fields: {
    subLevelParentLabel: string;
    internalLink: MainLink;
  };
};

export type Href = {
  id: string;
  path: string;
  contentPath: string;
  status: string;
  created: string;
  modified: string;
  title: string;
  description: string;
  model: Model;
  fields: {
    pageTitle: string;
  };
};

export type Model = {
  id: string;
  path: string;
  title: string;
  metadata: Metadata;
};

export type Metadata = {
  baseUrl: string;
  id: string;
  serverSrc: string;
  src: string;
  version: string;
};
