import React, { RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import PopOverNav from '../../components/navigation/PopOverNav';
import { SameLevelInpageNavigation } from '../../../types';
import { useInPageDispatch, useInPageNavContext } from '../../context';
import { throttle } from '../../utils/utils';
import { INPAGENAV_ACTIONS } from '../../reducers/inPageNavReducer';

export interface IPriorityNavProps {
  refNavUpdated: RefObject<HTMLElement> | undefined;
  refListUpdated: RefObject<HTMLElement> | undefined;
  activePriortyDropDown: boolean | undefined;
}

const howManyItemsInMenuArray = (array: number[], outerWidth: number) => {
  let total = 0;
  let items = 0;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < array.length; i++) {
    total += array[i];
    if (total > outerWidth) {
      items += 1;
    }
  }

  return items;
};

export const usePriorityNav = (
  props: IPriorityNavProps,
): {
  popOverNav: React.ReactElement | null;
} => {
  const [priorityItems, setPriorityItems] = useState<SameLevelInpageNavigation[]>([]);
  const {
    sameLevelInpageNavigation,
    topLevelActiveLink,
    secondLevelActiveLink,
    prioprityNavActive,
  } = useInPageNavContext();
  const [activeLink, setActiveLink] = useState<number>(0);
  const [priorityItemslength, setPriorityItemslength] = useState<number>(0);
  const dispatch = useInPageDispatch();

  const widthsArr = useMemo(() => {
    if (!props.refListUpdated?.current || !prioprityNavActive?.isDesktop) return [];
    const items = [...props.refListUpdated.current.children];
    const itemsArr = items.map((item) => item.clientWidth).filter((item) => item > 0); // remove any hidden items
    itemsArr.pop(); // remove the last item which is the toggle dropdown menu icon

    // fix for initial load and resizing the navigation menu from large to small
    // and last the element item wraps and disappears
    if (priorityItemslength === 0) {
      const lastElement = itemsArr.at(-1) || 0;
      itemsArr.push(lastElement + 50);
    }
    return itemsArr;
  }, [props.refListUpdated, sameLevelInpageNavigation, prioprityNavActive]);

  const updateNavigation = useCallback(() => {
    // Get width of all items in navigation menu
    const outerWidth = props.refNavUpdated?.current?.offsetWidth || 0;
    const arrayAmount = howManyItemsInMenuArray(widthsArr, outerWidth || 0) || 0;
    const itemsLength = sameLevelInpageNavigation?.length || 0;
    const slicedAmount = itemsLength - arrayAmount;
    const prioItems = slicedAmount < 0 ? [] : sameLevelInpageNavigation?.slice(slicedAmount);

    if (prioItems && prioItems?.length > 0) {
      const currentPath = window?.location.pathname;
      // get index using the href/path from prioItems to trigger the correct second level active menu
      // renders only when overview link is selected
      const arrIndex = prioItems
        ?.map((item, i) => ({ item, i }))
        .filter(({ item }) => item.fields.href?.path === currentPath)
        .map(({ i }) => i);
      const indx = Number(arrIndex?.[0]);

      setActiveLink(indx);
    }
    setPriorityItems(prioItems || []);
    if (dispatch) {
      dispatch({
        type: INPAGENAV_ACTIONS.PRIORITY_NAVIGATION,
        payload: {
          priorityNavigation: prioItems,
        },
      });
    }
    setPriorityItemslength(prioItems?.length || 0);
  }, [widthsArr]);

  // check if secondary-level-links were selected
  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(activeLink)) {
      if (topLevelActiveLink && secondLevelActiveLink && priorityItems?.length > 0) {
        const arrIndex = priorityItems
          ?.map((item, i) => ({ item, i }))
          .filter(({ item }) => item.fields.href?.path === topLevelActiveLink?.href)
          .map(({ i }) => i);

        const indx = Number(arrIndex?.[0]);
        setActiveLink(indx);
      }
    }
  }, [activeLink, topLevelActiveLink, secondLevelActiveLink, priorityItems]);

  // onload trigger
  useEffect(() => {
    if (widthsArr.length > 0) updateNavigation();
  }, [widthsArr]);

  useEffect(() => {
    const handleResize = throttle(() => {
      updateNavigation();
    }, 100);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [updateNavigation]);

  const popOverNav = useMemo(() => {
    return priorityItems.length > 0 ? (
      <PopOverNav
        visible={props.activePriortyDropDown || false}
        prioprityNavigation={priorityItems}
        activeLink={activeLink}
      />
    ) : null;
  }, [props.activePriortyDropDown, priorityItems]);

  return {
    popOverNav,
    activeLink,
    priorityItemslength,
  } as {
    popOverNav: React.ReactElement | null;
    activeLink: number;
    priorityItemslength: number;
  };
};
export default usePriorityNav;
