/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react';
import {
  ActiveSubLevelItem,
  ImpressionTrackingType,
  MainLink,
  TopLinksActive,
} from '../../../types';
import {
  getElementsByClass,
  getItemHref,
  getItemTitle,
  getMainItemHref,
  getSubLevelChildLabel,
  getTopLevelActiveItemHref,
  hasSubLinks,
  isSmallScreen,
} from '../../utils/utils';
import { useInPageDispatch, useInPageNavContext } from '../../context';
import { INPAGENAV_ACTIONS } from '../../reducers/inPageNavReducer';

const CLASS_NAME = 'inpage-nav-menu';
const TOP_LEVEL_LINKS_CLASS = 'top-level-link';
const TOP_LEVEL_LABEL_CLASS = 'top-level-label';

/**
 * Compares the last segments of two URLs
 * @param url1 First URL to compare
 * @param url2 Second URL to compare
 * @returns boolean indicating if last segments match
 */
function compareUrlLastSegments(url1: string, url2: string): boolean {
  try {
    /**
     * Gets the last segment of a URL
     * @param url URL to process
     * @returns The last segment of the URL
     */
    if (!url1?.trim() || !url2?.trim()) return false;
    const getLastSegment = (url: string): string => {
      // Remove trailing slash if exists
      const cleanUrl: string = url.replace(/\/+$/, '');
      // Split by '/' and get the last non-empty segment
      const segments: string[] = cleanUrl.split('/').filter(Boolean);
      return segments[segments.length - 1] || '';
    };

    const segment1: string = getLastSegment(url1);
    const segment2: string = getLastSegment(url2);

    return segment1 === segment2;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error comparing URLs:', error instanceof Error ? error.message : error);
    return false;
  }
}

const useActiveLink = (
  setIsSublinksActiveUrl: (setIsSublinksActiveUrl: boolean) => void,
  navSelector: string = `nav.${CLASS_NAME} a`,
  activeClass: string = 'active-link',
): void => {
  const dispatch = useInPageDispatch();
  const {
    accordionLabel,
    parentPageInpageNavigation,
    sameLevelInpageNavigation,
    sameLevelActiveLink,
    priorityNavLoaded,
    topActiveLinks,
  } = useInPageNavContext();
  const [activeSubLevelItems, setActiveSubLevelItems] = useState<ActiveSubLevelItem[]>([]);
  const [topLinksActive, setTopLinksActive] = useState<TopLinksActive[]>([]);
  // intActiveItems use to count each time a non parent page state is updated to help determine default impression tracking
  const [intActiveItems, setIntActiveItems] = useState(0);
  // 'checkDefaultImpression' use to set default impression tracking if on top main parent page or landing page with no parent overview is active
  const [checkDefaultImpression, setcheckDefaultImpression] = useState(false);

  // First check top level links
  useEffect(() => {
    const currentPath = typeof window !== 'undefined' ? window?.location?.pathname : '';
    const activeItems: ActiveSubLevelItem[] = [];
    if (sameLevelInpageNavigation && sameLevelInpageNavigation?.length > 0) {
      sameLevelInpageNavigation?.forEach((item, i) => {
        const href = getMainItemHref(item.fields.href);

        const isComparedUrl = compareUrlLastSegments(currentPath, href);

        if (isComparedUrl) {
          const url = getTopLevelActiveItemHref(sameLevelInpageNavigation, i);
          setTopLinksActive((prev) => [
            ...prev,
            {
              index: i,
              url,
            },
          ]);

          if (dispatch) {
            const text = getItemTitle(item.fields);
            dispatch({
              type: INPAGENAV_ACTIONS.SAME_LEVEL_ACTIVE_LINK,
              payload: {
                sameLevelActiveLink: {
                  id: item.id,
                  index: i,
                  text,
                  subLevelChildLabel: getSubLevelChildLabel(item.fields),
                  href: url,
                  // @ts-expect-error
                  target: item.fields.Target as string | undefined,
                },
                impressionTracking: {
                  type: ImpressionTrackingType.SAMELEVEL,
                  label: text,
                  value: 'same level',
                },
                isLoadedFromUrl: true,
              },
            });
            setIntActiveItems((prev) => prev + 1);
          }
        }

        // populate second level links
        const subLinks: MainLink[] = hasSubLinks(i, sameLevelInpageNavigation);
        const isSubLinks = subLinks.length > 0;
        if (isSubLinks) {
          const activeSubLevelItem: ActiveSubLevelItem = {
            id: item.id,
            parentLabel: getItemTitle(item.fields),
            subLevelChildLabel: getSubLevelChildLabel(item.fields),
            index: i,
            subLevelInpageNavigation: subLinks,
          };
          activeItems.push(activeSubLevelItem);
        }
      });

      setActiveSubLevelItems(activeItems);
    }
  }, [priorityNavLoaded, navSelector, activeClass]);

  useEffect(() => {
    if (sameLevelActiveLink && sameLevelActiveLink?.subLevelChildLabel) {
      setIsSublinksActiveUrl(true);
    }
  }, [sameLevelActiveLink]);

  // Now check if url matches any second level links
  useEffect(() => {
    if (activeSubLevelItems?.length > 0) {
      const currentPath = typeof window !== 'undefined' ? window?.location?.pathname : '';
      activeSubLevelItems.forEach((subitem: ActiveSubLevelItem) => {
        const overviewLabel = subitem.subLevelChildLabel || subitem.parentLabel;
        subitem.subLevelInpageNavigation?.forEach((item, ii) => {
          const href = getItemHref(item.fields.href);
          const isComparedUrl = compareUrlLastSegments(currentPath, href);

          if (isComparedUrl) {
            // Set to true to activate sublevel toggle function
            setIsSublinksActiveUrl(true);
            if (dispatch) {
              const url = getTopLevelActiveItemHref(sameLevelInpageNavigation, subitem.index);

              setTopLinksActive((prev) => [
                ...prev,
                {
                  index: Number(subitem.index),
                  url,
                },
              ]);

              dispatch({
                type: INPAGENAV_ACTIONS.TOP_LEVEL_ACTIVE_LINK,
                payload: {
                  topLevelActiveLink: {
                    id: subitem.id,
                    index: subitem.index,
                    text: subitem.parentLabel,
                    subLevelChildLabel: overviewLabel,
                    href: url,
                    target: item.fields.Target as string | undefined,
                  },
                  impressionTracking: {
                    type: ImpressionTrackingType.TOPLEVEL,
                    label: subitem.parentLabel,
                    value: '1 sub level',
                  },
                },
              });
              setIntActiveItems((prev) => prev + 1);

              const urlSecond = getTopLevelActiveItemHref(subitem.subLevelInpageNavigation, ii);

              dispatch({
                type: INPAGENAV_ACTIONS.SUB_LEVEL_ACTIVE_LINK,
                payload: {
                  secondLevelActiveLink: {
                    id: item.id,
                    index: subitem.index,
                    text: item.fields.Text,
                    href: urlSecond,
                    parentLabel: overviewLabel,
                    urlIndex: ii,
                    target: item.fields.Target as string | undefined,
                    overviewLinkSelected: false,
                  },
                },
              });

              dispatch({
                type: INPAGENAV_ACTIONS.IS_LOADED_FROM_URL,
                payload: {
                  isLoadedFromUrl: true,
                },
              });
            }
          }
        });
        setcheckDefaultImpression(true);
      });
    }
    if (!parentPageInpageNavigation) setcheckDefaultImpression(true);
  }, [activeSubLevelItems]);

  useEffect(() => {
    if (topLinksActive?.length > 0) {
      if (dispatch) {
        dispatch({
          type: INPAGENAV_ACTIONS.TOP_ACTIVE_LINKS,
          payload: {
            topActiveLinks: topLinksActive,
          },
        });
      }
    }
  }, [topLinksActive]);

  useEffect(() => {
    if (checkDefaultImpression && intActiveItems === 0) {
      if (dispatch) {
        dispatch({
          type: INPAGENAV_ACTIONS.IMPRESSION_TRACKING,
          payload: {
            impressionTracking: {
              type: ImpressionTrackingType.PARENT,
              label: `${isSmallScreen() ? `${accordionLabel || ''} ` : ''}${parentPageInpageNavigation?.fields.subLevelParentLabel || ''}`,
              value: `${isSmallScreen() ? '1 sub level' : 'same level'}`,
            },
          },
        });
      }
    }
  }, [checkDefaultImpression, intActiveItems]);

  // apply active class to top level links and top level labels
  useEffect(() => {
    if (sameLevelInpageNavigation?.length && topActiveLinks?.length) {
      // find all top level links desktop
      const topNavLinks = Array.from(
        getElementsByClass(TOP_LEVEL_LINKS_CLASS) as HTMLCollectionOf<HTMLAnchorElement>,
      );
      // find all top level labels on mobile
      const topNavLabels = Array.from(
        getElementsByClass(TOP_LEVEL_LABEL_CLASS) as HTMLCollectionOf<HTMLSpanElement>,
      );

      topActiveLinks.forEach(({ url }) => {
        [...topNavLinks, ...topNavLabels].forEach((element) => {
          const elemUrl = element.getAttribute('href') || element.dataset.href;
          if (elemUrl === url) {
            element.classList.add(activeClass);
          }
        });
      });
    }
  }, [topActiveLinks]);
};

export default useActiveLink;
