/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { UeContainer, UeElement, UeReference } from '@oneaudi/falcon-tools';
import React, { useContext } from 'react';
import { ItemLinkProps } from '../../../interfaces';
import { TrackingServiceContext, useInPageNavContext } from '../../context';
import { StyledLink } from './NavigationStyles';
import { getIndexPosition, useTracking } from '../../hooks/use-tracking';
import { subLinksRelatedElements, trackClickEvent } from '../../utils/tracking-utils';
import { hasSubLinks } from '../../utils/utils';

export const ItemLink = ({
  index,
  path,
  handleSelectedItem,
  hasSubMenu,
  subLevelChildLabel,
  title,
  isLastElement,
  hasParentElement,
  isSecondaryLevel,
  prioprityNavigationActiveIndex,
  target,
}: ItemLinkProps) => {
  const {
    sameLevelInpageNavigation,
    sameLevelActiveLink,
    topLevelActiveLink,
    secondLevelActiveLink,
    subNavActive,
  } = useInPageNavContext();
  const { trackingService } = useContext(TrackingServiceContext);
  const tracking = useTracking(trackingService);

  const currentPath = typeof window !== 'undefined' ? window?.location?.pathname : '';
  let secondLevelActive =
    secondLevelActiveLink?.href === currentPath &&
    secondLevelActiveLink?.urlIndex === index &&
    !subNavActive;
  // second level active links
  if (subNavActive) {
    secondLevelActive =
      (secondLevelActiveLink?.href === currentPath &&
        secondLevelActiveLink?.urlIndex === index &&
        topLevelActiveLink?.index === secondLevelActiveLink?.index) ||
      path === currentPath;
  }

  // top level active links and same level active links
  const topLevelActive =
    (topLevelActiveLink && index === topLevelActiveLink?.index && !subNavActive) ||
    (sameLevelActiveLink && index === sameLevelActiveLink?.index && !subNavActive);
  const activeTopLevel =
    secondLevelActiveLink?.href === currentPath &&
    secondLevelActiveLink?.index === index &&
    subNavActive;
  // let activeClass: boolean = false;
  const overviewActive =
    sameLevelActiveLink?.href === currentPath &&
    index === -1 &&
    subNavActive?.index === sameLevelActiveLink?.index;
  const prioprityNavigationActive = prioprityNavigationActiveIndex === index;

  return (
    <UeContainer propertyPath="sameLevelInpageNavigation" label="Navigation item">
      <UeReference
        propertyPath={
          isSecondaryLevel
            ? `sameLevelInpageNavigation.${topLevelActiveLink?.index}.subLinks.${index}`
            : `sameLevelInpageNavigation.${index}`
        }
        label="Link field"
      >
        <StyledLink
          className={`sibling-link ${prioprityNavigationActive ? 'active-link' : isSecondaryLevel ? `secondary-level-link ${secondLevelActive || overviewActive ? 'active-link' : ''}` : `top-level-link ${topLevelActive || activeTopLevel ? 'active-link' : ''}`}`}
          href={path}
          role="menuitem"
          aria-expanded
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            // Get the link element that was clicked. If the target is not an a-tag, get the closest a-tag.
            const linkElement =
              e.target instanceof HTMLAnchorElement ? e.target : (e.target as Element).closest('a');
            // Get the class list of the link element.
            const classList = linkElement?.classList;
            // Check if the link is currently active.
            const isActive = classList?.contains('active-link');

            if (handleSelectedItem && (!isActive || hasSubMenu) && !topLevelActive) {
              handleSelectedItem({
                id: `${title?.replace(/ /g, '-')}-link`,
                index,
                text: title,
                subLevelChildLabel,
                href: path,
                target,
              });
            }

            if (typeof window !== 'undefined' && !hasSubMenu && path && !isActive) {
              // tracking for link clicks
              const trackingTitle = isSecondaryLevel
                ? `${topLevelActiveLink?.text} > ${title}`
                : title;
              const trackingValue = isSecondaryLevel ? '1 sub level' : 'same level';
              const trackingPosition: string = isSecondaryLevel
                ? `${getIndexPosition(topLevelActiveLink?.index as number)}-${getIndexPosition(index)}`
                : getIndexPosition(index).toString();

              const relatedElements = isSecondaryLevel
                ? subLinksRelatedElements(
                    hasSubLinks(topLevelActiveLink?.index as number, sameLevelInpageNavigation),
                  )
                : [];

              trackClickEvent(
                tracking,
                trackingTitle,
                trackingValue,
                path,
                trackingPosition,
                relatedElements,
              );
              // eslint-disable-next-line no-unused-expressions
              target === '_blank' ? window.open(path) : (window.location.href = path);
            }
          }}
          data-index={index}
          $hasParentElement={hasParentElement}
          $isSecondaryLevel={isSecondaryLevel}
          $isLastElement={isLastElement}
          data-testid={`data-testid-${title}`}
          target={target}
        >
          <UeElement type="text" property="Text" label="Navigation item">
            {title}
          </UeElement>
        </StyledLink>
      </UeReference>
    </UeContainer>
  );
};
