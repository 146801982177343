/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import { ImpressionTrackingObject, MainLink, SameLevelInpageNavigation } from '../../types';
import { getIndexPosition, Tracking, TrackingRelatedElements } from '../hooks/use-tracking';
import { getItemTitle, getTrackingLabelDisplay, isSmallScreen } from './utils';

/**
 * Tracks an impression event for in-page navigation.
 *
 * The impression event is used to track when the in-page navigation is rendered
 * on the page. The event is tracked with the following attributes:
 *
 * @param {Tracking} tracking - The tracking service instance.
 * @param {ImpressionTrackingObject} trackingObject - An object containing the
 *   tracking information for the in-page navigation.
 * @param {SameLevelInpageNavigation[]} [topLevelLinks] - An array of same level
 *   links that are being tracked.
 * @param {MainLink[]} [relatedLinks] - An array of related links that are being
 *   tracked.
 */
export const trackImpressionEvent = (
  tracking: Tracking,
  trackingObject: ImpressionTrackingObject,
  topLevelLinks?: SameLevelInpageNavigation[],
  relatedLinks?: MainLink[],
): void => {
  const relatedElements = relatedLinks?.length
    ? subLinksRelatedElements(relatedLinks)
    : !isSmallScreen()
      ? topLevelRelatedElements(topLevelLinks)
      : topLevelMobileRelatedElements(trackingObject);

  tracking.impression({
    elementName: '',
    label: '',
    clickID: '',
    targetURL: '',
    value: trackingObject.value,
    pos: '',
    relatedElements,
  });
};

/**
 * Tracks the toggle event for in-page navigation.
 *
 * The toggle event is used to track the expansion or collapse of a menu or a
 * dropdown. The event is tracked with the following attributes:
 *
 * @param {Tracking} tracking - The tracking service instance.
 * @param {string} eventName - The name of the event that is being tracked.
 * @param {string[]} labelArray - The array of labels for the element that is being toggled.
 * @param {string} [value] - The value of the element that is being toggled.
 * @param {string} [targetURL] - The URL that the element that is being toggled links to.
 * @param {number | string} [index] - The position of the element that is being toggled in the menu or dropdown.
 * @param {TrackingRelatedElements[]} [relatedElements] - An array of related elements that are being tracked.
 */
export const trackToggleEvent = (
  tracking: Tracking,
  eventName: string,
  labelArray: string[],
  value?: string,
  targetURL?: string,
  index?: number | string,
  relatedElements?: TrackingRelatedElements[],
): void => {
  const label = getTrackingLabelDisplay(labelArray);

  tracking.toggleNavigation({
    elementName: 'text link',
    label,
    clickID: '',
    value,
    targetURL: targetURL || '',
    pos: index ? `${getIndexPosition(index as number)}` : '',
    eventName,
    relatedElements,
  });
};

/**
 * Tracks a click event for in-page navigation.
 *
 * The click event is used to track when a user clicks on a link in the in-page
 * navigation. The event is tracked with the following attributes:
 *
 * @param {Tracking} tracking - The tracking service instance.
 * @param {string} label - The label of the element that was clicked.
 * @param {string} value - The value of the link that was clicked.
 * @param {string} targetURL - The URL that the link that was clicked links to.
 * @param {string} position - The position of the element that was clicked in the menu or dropdown.
 * @param {TrackingRelatedElements[]} [relatedElements] - An array of related elements that are being tracked.
 */
export const trackClickEvent = (
  tracking: Tracking,
  label: string,
  value: string,
  targetURL: string,
  position: string,
  relatedElements?: TrackingRelatedElements[],
): void => {
  tracking?.navigationItemClick({
    elementName: 'text link',
    label,
    clickID: '',
    targetURL,
    value,
    pos: position,
    eventName: `inpage navigation - click on link`,
    relatedElements,
  });
};

/**
 * Constructs an array of top level related elements for tracking purposes for mobile.
 *
 * @param {ImpressionTrackingObject | undefined} [trackingObject] - The tracking object to be transformed into related elements.
 * @returns {Array<{ elementName: string; elementValue: string }>} An array of related element objects.
 */
export const topLevelMobileRelatedElements = (
  trackingObject?: ImpressionTrackingObject,
): TrackingRelatedElements[] => {
  if (trackingObject) {
    return [{ elementName: 'navigation item', elementValue: trackingObject.label }];
  }
  return [];
};

/**
 * Constructs an array of top level related elements for tracking purposes.
 *
 * @param {SameLevelInpageNavigation[]} [links] - The same level links to be transformed into related elements.
 * @returns {Array<{ elementName: string; elementValue: string }>} An array of related element objects.
 */
export const topLevelRelatedElements = (
  links?: SameLevelInpageNavigation[],
): TrackingRelatedElements[] => {
  const elements = links?.map((items: SameLevelInpageNavigation) => {
    const label = getItemTitle(items.fields);
    return {
      elementName: 'navigation item',
      elementValue: label,
    };
  });
  return elements || [];
};

/**
 * Constructs an array of sub-level related elements for tracking purposes.
 *
 * @param {MainLink[]} [links] - The sub-level links to be transformed into related elements.
 * @returns {Array<{ elementName: string; elementValue: string }>} An array of related element objects.
 */
export const subLinksRelatedElements = (links?: MainLink[]): TrackingRelatedElements[] => {
  const elements =
    links?.map(({ fields }) => ({
      elementName: 'navigation item',
      elementValue: getItemTitle(fields),
    })) || [];
  return elements;
};
