/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Theme as AudiTheme } from '@audi/audi-ui-react';
import { ActiveLinks, Fields, Href, InternalLink, SameLevelInpageNavigation } from '../../types';

export const isDarkTheme = (theme: AudiTheme): boolean => theme.name === 'Audi Dark Theme';

export const isFunction = (functionName: unknown): boolean => typeof functionName === 'function';

/**
 * Checks if the given same level inpage navigation at the given index has sub links.
 *
 * @param {number} index - The index of the same level inpage navigation to check.
 * @param {SameLevelInpageNavigation[] | undefined} sameLevelInpageNavigation - The same level inpage navigation to check.
 * @returns {SameLevelInpageNavigation['fields']['subLinks'] | undefined} - The sub links of the same level inpage navigation at the given index, or undefined if the same level inpage navigation does not exist at the given index or does not have sub links.
 */
export const hasSubLinks = (
  index: number,
  sameLevelInpageNavigation: SameLevelInpageNavigation[] | undefined,
) => {
  return sameLevelInpageNavigation?.[index]?.fields?.subLinks || [];
};

/**
 * Determines if the given parent page inpage navigation is defined and not null.
 *
 * @param {InternalLink | null | undefined} parentPageInpageNavigation - The parent page inpage navigation to check.
 * @returns {boolean} - True if the parentPageInpageNavigation is not null or undefined, false otherwise.
 */
export const isParentElement = (
  parentPageInpageNavigation: InternalLink | null | undefined,
): boolean => {
  return parentPageInpageNavigation !== undefined && parentPageInpageNavigation !== null;
};

/**
 * Gets the sub level parent label of the parent page inpage navigation.
 *
 * @param {InternalLink | undefined} parentPageInpageNavigation - The parent page inpage navigation to get the sub level parent label from.
 * @returns {string} - The sub level parent label of the parent item.
 */
export const getParentItemTitle = (parentPageInpageNavigation?: InternalLink): string => {
  return parentPageInpageNavigation?.fields?.subLevelParentLabel || '';
};

/**
 * Gets the content path href of the active top level item from the given same level inpage navigation and index.
 *
 * @param {SameLevelInpageNavigation[] | undefined} sameLevelInpageNavigation - The same level inpage navigation to get the content path href from.
 * @param {number} index - The index of the top level item to get the content path href for.
 * @returns {string} - The content path href of the active top level item.
 */
export const getTopLevelActiveItemHref = (
  sameLevelInpageNavigation: SameLevelInpageNavigation[] | undefined,
  index: number,
): string => {
  return getUrlType(
    sameLevelInpageNavigation?.[index]?.fields.href?.path,
    sameLevelInpageNavigation?.[index]?.fields.linkUrl,
  );
};

/**
 * Gets the content path href of the parent item from the given parent page inpage navigation.
 *
 * @param {InternalLink | undefined} parentPageInpageNavigation - The parent page inpage navigation to get the content path href from.
 * @returns {string | undefined} - The content path href of the parent item, or undefined if the parent item is undefined.
 */
export const getParentItemHref = (
  parentPageInpageNavigation?: InternalLink,
): string | undefined => {
  return parentPageInpageNavigation?.fields?.internalLink?.path;
};

/**
 * Gets the content path href of the item from the given Href object.
 *
 * @param {Href | undefined} itemInpageNavigation - The Href object to get the content path href from.
 * @returns {string | undefined} - The content path href of the item, or undefined if the item is undefined.
 */
export const getContentPathHref = (itemInpageNavigation?: Href): string | undefined => {
  return itemInpageNavigation?.contentPath;
};

/**
 * Gets the href of the main item from the given Href object.
 *
 * @param {Href | undefined} itemInpageNavigation - The Href object to get the href from.
 * @returns {string} - The href of the main item.
 */
export const getMainItemHref = (itemInpageNavigation?: Href): string => {
  return itemInpageNavigation?.path || '';
};

/**
 * Gets the href of the item from the given Href object.
 *
 * @param {Href | undefined} itemInpageNavigation - The Href object to get the href from.
 * @returns {string} - The href of the item.
 */
export const getItemHref = (itemInpageNavigation?: Href): string => {
  return itemInpageNavigation?.path || '';
};

/**
 * Gets the title of the item from the given fields object.
 *
 * @param {Fields} fields - The fields object to get the title from.
 * @returns {string} - The title of the item.
 */
export const getItemTitle = (fields: Fields): string => {
  // @ts-ignore
  return (fields.Text as string) || '';
};

/**
 * Gets the target of the link from the given fields object.
 *
 * @param {Fields} fields - The fields object to get the target from.
 * @returns {string} - The target of the link, either '_self' or '_blank'.
 */
export const getLinkTarget = (fields: Fields): string => {
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return fields.Target || '_self';
};

/**
 * Gets the sub level child label from the given fields object.
 *
 * @param {Fields} fields - The fields object to get the sub level child label from.
 * @returns {string} - The sub level child label.
 */
export const getSubLevelChildLabel = (fields: Fields): string => {
  return fields?.subLevelChildLabel || '';
};

/**
 * Checks if the given second level active link is a secondary level link (i.e. not the overview link).
 *
 * @param {ActiveLinks | undefined} secondLevelActiveLink - The second level active link.
 * @returns {boolean} - True if the given second level active link is a secondary level link, false otherwise.
 */
export function isSecondaryLevelFromUrl(secondLevelActiveLink: ActiveLinks | undefined): boolean {
  return typeof secondLevelActiveLink?.index === 'number' && secondLevelActiveLink.index > -1;
}

/**
 * Gets all elements with the given class name.
 *
 * @param {string} className - The class name.
 * @returns {HTMLCollectionOf<Element>} - The elements with the given class name.
 */
export function getElementsByClass(className: string): HTMLCollectionOf<Element> {
  return document.getElementsByClassName(className);
}

/**
 * Gets the URL type. If pageFlexibleUrl is not defined and externalLinkUrl is defined,
 * it returns the externalLinkUrl after removing any whitespace characters. Otherwise,
 * it returns the pageFlexibleUrl.
 *
 * @param {string | undefined} pageFlexibleUrl - The URL of the page.
 * @param {string | undefined} externalLinkUrl - The URL of the external link.
 * @returns {string} - The URL type.
 */
export function getUrlType(pageFlexibleUrl: string | undefined, externalLinkUrl?: string): string {
  if (!pageFlexibleUrl && externalLinkUrl) {
    const url = externalLinkUrl.replace(/\s/g, '');
    return url;
  }
  return pageFlexibleUrl || '';
}

export function throttle<T extends (...args: any[]) => void>(
  func: T,
  interval: number,
): (...args: Parameters<T>) => void {
  let lastCall = 0;
  return function throttledFunction(this: any, ...args: Parameters<T>): void {
    const now = Date.now();
    if (now - lastCall >= interval) {
      lastCall = now;
      func.apply(this, args);
    }
  };
}

/**
 * Takes an array of strings and returns a string with the elements separated by " > ".
 * @param {string[]} labelArray An array of strings to be joined.
 * @returns {string} The joined string.
 */
export const getTrackingLabelDisplay = (labelArray: string[]): string => {
  return labelArray.join(' > ');
};

/**
 * Checks if a given URL is absolute.
 *
 * @param {string} url - The URL to be checked.
 * @returns {boolean} - True if the URL is absolute, false otherwise.
 */
export function isAbsoluteURL(url: string): boolean {
  return url.startsWith('http://') || url.startsWith('https://');
}

/**
 * Determines if the screen size is considered small based on window width.
 *
 * @returns {boolean} - True if the screen width is less than 1024px, false otherwise.
 */
export const isSmallScreen = (): boolean => {
  if (typeof window !== 'undefined') {
    return window.innerWidth < 1024;
  }
  return false;
};
